
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import Swal from "sweetalert2";
import CompanyService from "@/core/services/CompanyService";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import CompanyForm from "@/views/company/CompanyForm.vue";
import {ModelAddress} from "@/core/entity/IAddress";

export default defineComponent({
  name: "Clients",
  components: {CompanyForm, BaseModal, KtDatatable, QuickAction, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Company",
        [
          {link: false, router: '', text: 'Companies'}
        ]
      )
    })
    const companyModel = ref({name: '', code: '', billingAddress: '', parentId: '', type: 'CLIENT', address: new ModelAddress(), fullName :''});
    const companyId = ref('');

    const filterObject = ref({type: 'CLIENT'})
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Full Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Billing Address",
        key: "billingAddress",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      }
    ]);
    const companyPage = computed(() => store.state.CompanyModule.companyPage)
    return {
      ...LoadFilterObjects(Actions.LOAD_COMPANIES, filterObject.value, ['']),
      tableHeader,
      companyPage,
      companyModel,
      companyId,
    }
  },
  methods: {
    onCompanySaved() {
      const baseModal = this.$refs.refCompanyModal as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad()
    },
    deleteCompany(company: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CompanyService.delete(company.id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    onEditCompany(company: any) {
      if (company === null) {
        this.companyModel = {name: '', code: '', billingAddress: '', parentId: '', type :'CLIENT', address: new ModelAddress(), fullName :''}
        this.companyId = ''
      } else {
        this.companyId = company.id;
        this.companyModel = {
          address : company.address ? company.address : new ModelAddress(),
          type: company.type.code,
          name: company.name,
          code: company.code,
          billingAddress: company.billingAddress,
          parentId: company?.parent,
          fullName: company.fullName
        }
      }
    }
  }
})
